<template lang="">
  <ValidationProvider
    v-slot="{ errors, required, ariaInput }"
    :tag="`div`"
    class="grid grid-cols-12  "
    :rules="rules"
  >
    <div class="col-span-12  md:col-span-3 full-mode">
      <div class="label ">
        <input-label
          :error="errors[0]"
          :text="`${label}${required ? ' *' : ''}`"
        />
      </div>
    </div>

    <div class="responsive-mode col-span-12 my-3">
      <div class="flex justify-between items-center">
        <div class="label ">
          <input-label
            :error="errors[0]"
            :text="`${label}${required ? ' *' : ''}`"
          />
        </div>
        <div
          v-if="showEditPencil"
          class="col-span-12 pencil-icon md:col-span-3 justify-items-end justify-self-end"
        >
          <div v-if="!getEditStatus">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              @click="handleEdit(rowId)"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="{2}"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 md:col-span-6">
      <div class="grid grid-cols-12">
        <div class="col-span-11">
          <template v-if="type === 'richselect'">
            <div
              v-if="haveUnitText"
              class="flex items-center  rounded-md"
              :class="`${getUnitPortionBgVariant}-background`"
            >
              <div class="w-full">
                <t-rich-select
                  ref="inputRef"
                  placeholder="Select one"
                  :options="options"
                  valueAttribute="value"
                  textAttribute="text"
                  :hideSearchBox="isSearchBox"
                  :multiple="isMultipleChoice"
                  v-on="getListeners"
                  :variant="{
                    readonly: getDisableStatus,
                  }"
                  :disabled="getDisableStatus"
                  v-model="valueProxy"
                  @focus="focusHandler"
                  @blur="blurHandler"
                />
              </div>
              <div
                class="h-full  text-oWhite"
                :class="getUnitWidthVariantClass"
              >
                <p class="py-1 text-center">{{ unitText }}</p>
              </div>
            </div>
            <div v-else-if="isMultipleChoiceAndDisabled">
              <input
                v-if="
                  multipleChoiceType === 'vehicleType' ||
                    multipleChoiceType === 'countryDialCode'
                "
                ref="inputRef"
                class="block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500"
                :class="{
                  'border-gray-400 placeholder-gray-500': !errors[0],
                  'border-red-600 placeholder-red-500': errors[0],
                  'bg-oLightestGray text-oLightGray placeholder-oLightGray': getDisableStatus,
                  'has-value': hasValue,
                }"
                v-on="getListeners"
                :type="typeProxy"
                :disabled="getDisableStatus"
                :placeholder="getPlaceHolderForMultipleChoice"
              />

              <input
                v-else
                ref="inputRef"
                class="block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500"
                :class="{
                  'border-gray-400 placeholder-gray-500': !errors[0],
                  'border-red-600 placeholder-red-500': errors[0],
                  'bg-oLightestGray text-oLightGray placeholder-oLightGray': getDisableStatus,
                  'has-value': hasValue,
                }"
                v-on="getListeners"
                :type="typeProxy"
                :disabled="getDisableStatus"
                :placeholder="placeholder"
                v-model="valueProxy"
              />
            </div>
            <div v-else>
              <t-rich-select
                ref="inputRef"
                placeholder="Select one"
                :options="options"
                valueAttribute="value"
                textAttribute="text"
                :hideSearchBox="isSearchBox"
                v-on="getListeners"
                :variant="{
                  readonly: getDisableStatus,
                }"
                :disabled="getDisableStatus"
                v-model="valueProxy"
                :multiple="isMultipleChoice"
              />
            </div>
          </template>
          <template v-else-if="type === 'tel'">
            <vue-tel-input
              ref="inputRef"
              v-model="valueProxy"
              v-bind="{ ...$attrs }"
              v-on="getListeners"
              :class="{
                'border-gray-400 placeholder-gray-500 telinput':
                  !errors[0] || customError == '',
                'border-red-600 placeholder-red-400 custom-phone': errors[0],
                'bg-gray-200 text-oLightGray placeholder-oLightGray telinput': getDisableStatus,
                'has-value telinput': hasValue,
              }"
              validCharactersOnly
              :disabled="getDisableStatus"
              @input="onChangePhoneNumber"
            >
            </vue-tel-input>
          </template>

          <div v-else>
            <div v-if="type === 'date'">
              <input
                ref="inputRef"
                v-model="valueProxy"
                v-bind="{ ...ariaInput, ...$attrs }"
                v-on="getListeners"
                :type="type"
                :max="max"
                :disabled="getDisableStatus"
                class="block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500"
                :class="{
                  'border-gray-400 placeholder-gray-500': !errors[0],
                  'border-red-600 placeholder-red-400': errors[0],
                  'bg-oLightestGray text-oLightGray placeholder-oLightGray': getDisableStatus,
                  'has-value': hasValue,
                }"
              />
            </div>
            <div v-else>
              <div
                v-if="haveUnitText"
                class="flex items-center  rounded-md"
                :class="`${getUnitPortionBgVariant}-background`"
              >
                <div class="w-full">
                  <input
                    ref="inputRef"
                    class="block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500"
                    :class="{
                      'border-gray-400 placeholder-gray-500': !errors[0],
                      'border-red-600 placeholder-red-500': errors[0],
                      'bg-oLightestGray text-oLightGray placeholder-oLightGray': getDisableStatus,
                      'has-value': hasValue,
                    }"
                    v-on="getListeners"
                    :type="typeProxy"
                    :disabled="getDisableStatus"
                    :placeholder="placeholder"
                    v-model="valueProxy"
                    @focus="focusHandler"
                    @blur="blurHandler"
                  />
                </div>
                <div
                  class="h-full  text-oWhite"
                  :class="getUnitWidthVariantClass"
                >
                  <p class="py-1 text-center">{{ unitText }}</p>
                </div>
              </div>
              <div v-else>
                <input
                  ref="inputRef"
                  class="block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500"
                  :class="{
                    'border-gray-400 placeholder-gray-500': !errors[0],
                    'border-red-600 placeholder-red-500': errors[0],
                    'bg-oLightestGray text-oLightGray placeholder-oLightGray': getDisableStatus,
                    'has-value': hasValue,
                  }"
                  v-on="getListeners"
                  :type="typeProxy"
                  :disabled="getDisableStatus"
                  :placeholder="placeholder"
                  v-model="valueProxy"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="isShowChecker" class="col-span-1 relative flex items-center">
          <loading
            v-if="isCheckStart"
            :active="isCheckStart"
            :height="25"
            :opacity="0.5"
            :color="`#000`"
            :loader="`bars`"
            :is-full-page="false"
            :class="`outline-none`"
          />
          <div v-else>
            <span v-if="canBeSaved === true" class="pl-1">
              <i class="fas fa-check text-oGreen" />
            </span>
            <span v-if="canBeSaved === false" class="pl-1">
              <i class="fa fa-close text-oRed" />
            </span>
          </div>
        </div>
      </div>

      <p
        v-if="showDescription"
        :class="{ 'text-red-500': errors[0] }"
        class="description mb-2"
      >
        {{ description }}
      </p>
      <!-- Suggestion -->
      <div v-if="haveSuggestion" class="flex items-center min-h-6">
        <span
          class="block text-xs "
          :class="
            isSuggestionAlert ? 'text-red-500 font-semibold' : 'text-gray-700'
          "
          >{{ suggestionText }}</span
        >
      </div>
      <div v-if="getEditStatus" class="flex mt-3 mb-4">
        <anchor-button
          @click="handleCancel"
          :disabled="isCancelButtonDisabled"
          :text="$t('components.stepNavigation.cancel')"
          class="w-1/5 mr-2"
        />
        <anchor-button
          @click="handleSave"
          :text="$t('components.stepNavigation.save')"
          :isLoading="isSaveButtonLoading"
          :disabled="isSaveButtonDisabled"
          variant="success"
          class="w-1/5 ml-2"
        />
      </div>
    </div>

    <div
      v-if="showEditPencil"
      class="col-span-12 pencil-icon md:col-span-3 justify-items-end justify-self-end full-mode"
    >
      <div v-if="!getEditStatus">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          @click="handleEdit(rowId)"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="{2}"
            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
          />
        </svg>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
import AnchorButton from '@/components/form/AnchorButton'
import InputLabel from '@/components/form/InputLabel'
import { EventBus } from '@/utils/EventBus'
import { CountryDialingCodeObjectList } from '@/resources/CountryDialingCodeResouce'
const validTypes = [
  'url',
  'text',
  'email',
  'search',
  'number',

  'date',

  'textarea',

  'tel',
  'password',
  'richselect',
]

const validUnitWidthVariant = ['small', 'standard', 'large']

export default {
  name: 'SmartAppInput',
  props: {
    rowId: {
      type: [String, Number],
      default: 'AppInputId',
    },
    showEditPencil: {
      type: Boolean,
      default: true,
    },
    showDescription: {
      type: Boolean,
      default: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return validTypes.includes(value)
      },
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    value: {
      type: null,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSearchBox: {
      type: Boolean,
      default: true,
    },
    isMultipleChoice: {
      type: Boolean,
      default: false,
    },
    multipleChoiceType: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
    },
    max: {
      type: String,
    },
    isShowChecker: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCheckStart: {
      type: Boolean,
      required: false,
      default: false,
    },
    canBeSaved: {
      type: Boolean,
      required: false,
      default: null,
    },
    isSaveButtonLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCancelButtonDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSaveButtonDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    haveSuggestion: {
      type: Boolean,
      default: false,
    },
    isSuggestionAlert: {
      type: Boolean,
      default: false,
    },
    suggestionText: {
      type: String,
      default: '',
    },
    haveUnitText: {
      type: Boolean,
      default: false,
    },
    unitText: {
      type: String,
      default: '',
    },
    unitWidthVariant: {
      type: String,
      default: 'standard',
      validator(value) {
        return validUnitWidthVariant.includes(value)
      },
    },
  },
  created() {
    this.previousData = this.valueProxy
  },
  data() {
    return {
      typeProxy: '',
      valueProxy: '',
      editableStatus: false,
      previousData: null,
      unitPortionBgVariant: 'blur',
    }
  },

  components: {
    AnchorButton,
    InputLabel,
  },
  computed: {
    getPlaceHolderForMultipleChoice() {
      if (this.multipleChoiceType === 'vehicleType') {
        const vehicleTypeMap = {
          P: {
            text: 'Scooter',
          },
          PP: {
            text: 'Scooter Pro',
          },
          E: {
            text: 'E-Bike',
            displayText: 'E-Bike',
          },
          S: {
            text: 'Bike',
          },
          C: {
            text: 'SuperCoco',
          },
          K: {
            text: 'Kenota',
          },
          M: {
            text: 'Moped',
          },
          ALL: {
            text: 'All',
          },
          NONE: {
            text: 'None',
          },
        }
        const vehicleTypes = this.valueProxy.map(
          (item) => vehicleTypeMap[item].text
        )

        if (vehicleTypes.length > 0) {
          if (vehicleTypes.includes('All')) {
            return 'All'
          } else if (vehicleTypes.includes('None')) {
            return 'None'
          } else {
            return vehicleTypes.join(', ')
          }
        }
      }
      if (this.multipleChoiceType === 'countryDialCode') {
        const countryName = this.valueProxy.map(
          (item) => CountryDialingCodeObjectList[item].text
        )
        console.log('countryName', countryName)
        if (countryName.length === 0) {
          return 'None'
        } else {
          return countryName.join(', ')
        }
      }
      return this.placeholder
    },
    hasValue() {
      return !!this.valueProxy
    },
    getDisableStatus() {
      if (this.editableStatus) {
        return false
      } else {
        return true
      }
    },
    getEditStatus() {
      if (this.editableStatus) {
        return true
      } else {
        return false
      }
    },

    getListeners() {
      return Object.assign({}, this.$listeners, {
        // handle custom event as well as a fallback to the native events
        input: (event) => {
          const e =
            this.type === 'richselect' || this.type === 'tel'
              ? event
              : event.target.value
          this.$emit('input', e)
        },
      })
    },
    getUnitPortionBgVariant() {
      return this.unitPortionBgVariant
    },
    getUnitWidthVariantClass() {
      return `${this.unitWidthVariant}-unit-width`
    },
    isMultipleChoiceAndDisabled() {
      if (this.isMultipleChoice && this.getDisableStatus) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    value: {
      deep: false,
      immediate: true,
      handler(val) {
        if (val !== this.valueProxy) {
          this.valueProxy = val
        }
      },
    },

    type: {
      deep: false,
      immediate: true,
      handler(val) {
        if (val !== this.typeProxy) {
          this.typeProxy = val
        }
      },
    },
    isEdit: {
      deep: false,
      immediate: true,
      handler(status) {
        if (status) {
          this.editableStatus = true
        } else {
          this.editableStatus = false
          if (this.previousData !== null) {
            this.$emit('input', this.previousData)
          }
        }
      },
    },
  },
  methods: {
    onChangePhoneNumber(number, valid) {
      if (valid.isValid) {
        //true
        this.valueProxy = valid.number.input
        this.customError = ''
        // this.$emit('tel:valid', {
        //   valid: true,
        //   message: 'The phone number field is valid',
        // })
      } else {
        // this.$emit('tel:invalid', {
        //   valid: false,
        //   message: 'The phone number field must be a valid',
        // })
        // this.customError = 'The phone number field must be a valid'
      }
    },

    handleCancel() {
      this.editableStatus = !this.editableStatus
      if (this.previousData !== null) {
        this.$emit('input', this.previousData)
        this.$emit('cancel')
      }
    },
    handleSave() {
      this.editableStatus = !this.editableStatus
      this.$emit('save', this.valueProxy)
    },
    handleEdit(id) {
      EventBus.$emit('open-edit', id)
      this.editableStatus = !this.editableStatus
    },
    focus() {
      this.$refs.inputRef.focus()
    },
    focusHandler() {
      this.unitPortionBgVariant = 'focus'
      console.log('handler-focus')
    },
    blurHandler() {
      this.unitPortionBgVariant = 'blur'
      console.log('handler-blur')
    },
  },
}
</script>
<style lang="scss">
.pencil-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.description {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 10px;
  margin-bottom: 20px;
}
.focus-background {
  @apply bg-gray-900;
}

.blur-background {
  @apply bg-gray-700;
}

.small-unit-width {
  @apply w-16;
}
.standard-unit-width {
  @apply w-20;
}

.large-unit-width {
  @apply w-24;
}
</style>
